import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as ToastActions from '../actions/ToastActions';
import Toast from './Toast';

const enhance = compose(
  connect(
    ({ toasts }) => ({ toasts }),
    dispatch => ({
      dismiss: toastId => {
        dispatch(ToastActions.dismissToast(toastId));
      },
    })
  )
);

class Toasts extends Component {
  render() {
    const { toasts, dismiss } = this.props;
    return (
      <ul className="Toasts">
        {Object.keys(toasts).map(toastId => (
          <Toast
            key={toastId}
            id={toastId}
            isActive={toasts[toastId].isActive}
            message={toasts[toastId].message}
            buttonLabel={toasts[toastId].buttonLabel}
            buttonTarget={toasts[toastId].buttonTarget}
            dismiss={dismiss}
          />
        ))}
      </ul>
    );
  }
}

export default enhance(Toasts);
