import * as types from '../constants/ActionTypes';
import { cityEditor as initialState } from '../initialState';

export default function cityEditor(state = initialState, action) {
  switch (action.type) {
    case types.CITY_OPEN_EDITOR:
      return {
        isOpen: true,
        entityId: action.entityId,
        elementRect: action.elementRect,
        cityData: action.cityData,
      };
    case types.CITY_CLOSE_EDITOR:
      return initialState;
    case types.CITY_UPDATE_DATA:
      return Object.assign({}, state, {
        cityData: Object.assign({}, state.cityData, {
          name: action.title,
        }),
      });
    default:
      return state;
  }
}
