import React from 'react';
import { compose } from 'redux';
import { withProps } from 'recompose';
import { GoogleMap, withGoogleMap } from 'react-google-maps';

const enhance = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
);

class CityMap extends React.Component {
  constructor(props) {
    super(props);
    this.onProjectionChanged = this.onProjectionChanged.bind(this);
  }

  onProjectionChanged() {
    this.forceUpdate();
  }

  computeAdjustedCenter(center, zoom) {
    if (!this.map || !this.map.getProjection()) {
      return center;
    }

    const OFFSET = {
      x: -235,
      y: 0,
    };

    const centerLatLng = new window.google.maps.LatLng(center);
    const point1 = this.map.getProjection().fromLatLngToPoint(centerLatLng);
    const point2 = new window.google.maps.Point(
      (typeof OFFSET.x === 'number' ? OFFSET.x : 0) / 2 ** zoom || 0,
      (typeof OFFSET.y === 'number' ? OFFSET.y : 0) / 2 ** zoom || 0
    );
    const adjustedCenter = this.map
      .getProjection()
      .fromPointToLatLng(
        new window.google.maps.Point(point1.x - point2.x, point1.y + point2.y)
      );
    return adjustedCenter.toJSON();
  }

  render() {
    const { center, zoom } = this.props;
    return (
      <GoogleMap
        ref={c => {
          this.map = c;
        }}
        onProjectionChanged={this.onProjectionChanged}
        zoom={zoom}
        center={this.computeAdjustedCenter(center, zoom)}
        defaultCenter={center}
      />
    );
  }
}

export default enhance(CityMap);
