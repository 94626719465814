import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { geocodeByAddress } from 'react-places-autocomplete';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import unionClassNames from 'union-class-names';

import * as CityActions from '../../actions/CityActions';
import * as EditorActions from '../../actions/EditorActions';
import CityEditorInput from './CityEditorInput';

const preventBubblingUp = event => {
  event.stopPropagation();
};

const enhance = compose(
  withFirebase,
  withFirestore,
  connect(
    ({ cityEditor: { isOpen, entityId, cityData, elementRect } }) => ({
      isOpen,
      entityId,
      cityName: cityData.name,
      elementRect,
    }),
    dispatch => ({
      openCityEditor: (entityId, cityData, elementRect) => {
        dispatch(CityActions.openCityEditor(entityId, cityData, elementRect));
      },
      onUpdateTitle: title => {
        dispatch(CityActions.updateTitle(title));
      },
      updateCity: (result, description, entityId) => {
        dispatch(EditorActions.updateCity(result, description, entityId));
      },
      onClose: () => {
        dispatch(CityActions.closeCityEditor());
      },
    })
  )
);

class CityEditor extends Component {
  constructor(props) {
    super(props);
    this.onSelectPlace = this.onSelectPlace.bind(this);
  }

  onSelectPlace(city) {
    const { firestore, updateCity, onClose, entityId } = this.props;
    geocodeByAddress(city).then(results => {
      const result = results[0];
      const placeId = result.place_id;
      const cityName = result.address_components[0].long_name;
      firestore.get(`cities/${placeId}`).then(doc => {
        if (doc.exists) {
          const { description } = doc.data();
          updateCity(result, description, entityId);
          onClose();
        } else {
          fetch(
            `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(
              cityName
            )}`
          )
            .then(response => response.json())
            .then(wikipediaData => {
              const { type, extract, pageid } = wikipediaData;

              if (type === 'disambiguation') {
                // Try another way
                // TODO(dan) refactor this...
                fetch(
                  `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(
                    result.formatted_address
                  )}`
                )
                  .then(response => response.json())
                  .then(secondWikipediaData => {
                    const { extract2, pageid2 } = secondWikipediaData;
                    const description =
                      extract2.replace(/ \([^)]*\)/, '') ||
                      'Unable to retreive description.';
                    firestore.set(`cities/${placeId}`, {
                      name: cityName,
                      description,
                      pageId: pageid2,
                    });
                    updateCity(result, description, entityId);
                    onClose();
                  });
              }

              const description =
                extract.replace(/ \([^)]*\)/, '') ||
                'Unable to retreive description.';
              firestore.set(`cities/${placeId}`, {
                name: cityName,
                description,
                pageId: pageid,
              });
              updateCity(result, description, entityId);
              onClose();
            });
        }
      });
    });
  }

  preventBubblingUp(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    const {
      elementRect,
      isOpen,
      onUpdateTitle,
      cityName,
      onClose,
    } = this.props;
    const classNames = unionClassNames(
      'CityEditor',
      isOpen ? 'CityEditor-active' : ''
    );

    return (
      <div className={classNames} onClick={onClose} role="presentation">
        {isOpen ? (
          <form
            onClick={preventBubblingUp}
            onMouseDown={preventBubblingUp}
            onMouseUp={preventBubblingUp}
            role="presentation"
            style={{
              width: elementRect.width,
              height: elementRect.height,
              left: elementRect.x,
              top: elementRect.y,
            }}
          >
            <div className="City-map" />
            <div className="City-details">
              <CityEditorInput
                cityName={cityName}
                role="search"
                onChange={onUpdateTitle}
                onSelectPlace={this.onSelectPlace}
              />
              <p />
            </div>
          </form>
        ) : (
          <span />
        )}
      </div>
    );
  }
}

export default enhance(CityEditor);
