import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { routerReducer } from 'react-router-redux';
import cityEditor from './cityEditor';
import editor from './editor';
import post from './post';
import toasts from './toasts';

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  router: routerReducer,
  editor,
  cityEditor,
  post,
  toasts,
});

export default rootReducer;
