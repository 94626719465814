import * as types from '../constants/ActionTypes';
import { cityEditor as initialState } from '../initialState';

export default function toasts(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_TOAST: {
      return {
        ...state,
        [action.id]: {
          isActive: true,
          message: action.message,
          buttonLabel: action.buttonLabel,
          buttonTarget: action.buttonTarget,
        },
      };
    }

    case types.HIDE_TOAST: {
      if (!(action.id in state)) return state;

      return Object.assign({}, state, {
        [action.id]: Object.assign(state[action.id], {
          isActive: false,
        }),
      });
    }

    case types.DELETE_TOAST: {
      if (!(action.id in state)) return state;

      const r = Object.assign({}, state);
      delete r[action.id];
      return r;
    }

    default: {
      return state;
    }
  }
}
