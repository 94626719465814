import React, { Component } from 'react';
import unionClassNames from 'union-class-names';

class PlaceTagSuggestionsEntry extends Component {
  render() {
    const { mention, isFocused, searchValue, ...parentProps } = this.props;
    return (
      <div
        {...parentProps}
        className={unionClassNames(
          'PlaceTagSuggestionsEntry',
          isFocused ? 'is-focused' : ''
        )}
      >
        <strong>{mention.formattedSuggestion.mainText}</strong>
        <span>{mention.formattedSuggestion.secondaryText}</span>
      </div>
    );
  }
}

export default PlaceTagSuggestionsEntry;
