import { AtomicBlockUtils, EditorState } from 'draft-js';
import * as types from '../constants/ActionTypes';
import { editor as initialState } from '../initialState';

export default function editor(state = {}, action) {
  switch (action.type) {
    case types.EDITOR_UPDATE_EDITOR_STATE: {
      return Object.assign({}, state, {
        editorState: action.editorState,
      });
    }

    case types.EDITOR_UPDATE_TITLE: {
      return Object.assign({}, state, {
        title: action.title,
      });
    }

    case types.EDITOR_CLEAR_EDITOR_STATE: {
      return initialState;
    }

    case types.EDITOR_TOGGLE_RAW_STATE: {
      return Object.assign({}, state, {
        showRawState: !state.showRawState,
      });
    }

    case types.EDITOR_INSERT_CITY: {
      const contentState = state.editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'CITY',
        'IMMUTABLE',
        { name: '', id: null }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(state.editorState, {
        currentContent: contentStateWithEntity,
      });
      const finalEditorState = AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
      );
      return Object.assign({}, state, {
        editorState: finalEditorState,
      });
    }

    case types.EDITOR_UPDATE_CITY: {
      state.editorState.getCurrentContent().replaceEntityData(action.entityId, {
        id: action.result.place_id,
        name: action.result.address_components[0].long_name,
        description: action.description,
        types: action.result.types,
        location: {
          lat: action.result.geometry.location.lat(),
          lng: action.result.geometry.location.lng(),
        },
      });
      const selection = state.editorState.getSelection();
      return Object.assign({}, state, {
        editorState: EditorState.forceSelection(state.editorState, selection),
      });
    }

    case types.EDITOR_LOAD_FIRESTORE_DATA: {
      return Object.assign({}, state, {
        isFirestoreDataLoaded: true,
      });
    }

    case types.EDITOR_UPDATE_COVER_IMAGE: {
      return Object.assign({}, state, {
        coverImage: Object.assign({}, state.coverImage, action.coverImage),
      });
    }

    case types.EDITOR_DROP_COVER_IMAGE: {
      return Object.assign({}, state, {
        coverImage: Object.assign({}, state.coverImage, {
          isUploading: true,
          dataUrl: action.dataUrl,
        }),
      });
    }

    case types.EDITOR_DISPLAY_COVER_IMAGE: {
      return Object.assign({}, state, {
        coverImage: Object.assign({}, state.coverImage, {
          isUploading: false,
        }),
      });
    }

    case types.EDITOR_CLEAN_UP_COVER_IMAGE_DATA_URL: {
      URL.revokeObjectURL(state.coverImage.dataUrl);
      return Object.assign({}, state, {
        coverImage: Object.assign({}, state.coverImage, {
          dataUrl: null,
        }),
      });
    }

    case types.EDITOR_UPDATE_PLACE_TAG_SUGGESTIONS: {
      return Object.assign({}, state, {
        placeTagSuggestions: action.placeTagSuggestions,
      });
    }

    case types.EDITOR_UPDATE_AUTHOR: {
      return Object.assign({}, state, {
        author: action.author,
      });
    }

    case types.EDITOR_UPDATE_HAS_UPLOADING_IMAGES: {
      return Object.assign({}, state, {
        hasUploadingImages: action.hasUploadingImages,
      });
    }

    default: {
      return state;
    }
  }
}
