import * as ActionTypes from '../constants/ActionTypes';

const createToast = (id, message, buttonLabel, buttonTarget) => ({
  type: ActionTypes.CREATE_TOAST,
  id,
  message,
  buttonLabel,
  buttonTarget,
});

const hideToast = id => ({
  type: ActionTypes.HIDE_TOAST,
  id,
});

const deleteToast = id => ({
  type: ActionTypes.DELETE_TOAST,
  id,
});

export const dismissToast = toastId => dispatch => {
  dispatch(hideToast(toastId));
  setTimeout(() => {
    dispatch(deleteToast(toastId));
  }, 500);
};

let nextNotificationId = 0;

export const showToast = (message, buttonLabel, buttonTarget) => dispatch => {
  nextNotificationId += 1;
  const id = nextNotificationId;
  dispatch(createToast(id, message, buttonLabel, buttonTarget));
  setTimeout(() => {
    dispatch(dismissToast(id));
  }, 5000);
};
