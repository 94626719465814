import React, { Component } from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase';

import InsertCityButton from './editor/InsertCityButton';
import SavePostButton from './editor/SavePostButton';
import PublishPostButton from './editor/PublishPostButton';
import logoImg from '../img/onward-logo.svg';

const enhance = compose(
  withRouter,
  firebaseConnect(),
  connect(({ firebase: { auth, profile } }) => ({ auth, profile }))
);

const navbarCenterRenderFunction = ({
  match: {
    params: { postId },
  },
}) => (
  <ul className="Navbar-center">
    <InsertCityButton />
    <li className="Navbar-spacer" />
    <li className="Navbar-spacer" />
    <SavePostButton postId={postId} />
    <PublishPostButton postId={postId} />
  </ul>
);

class Navbar extends Component {
  renderAccountBadge() {
    const { firebase, profile } = this.props;
    return (
      <li className="AccountBadge">
        {isEmpty(profile) ? (
          <span className="AccountBadge-empty" />
        ) : (
          <React.Fragment>
            <div className="AccountBadge-navbar">
              <img src={profile.photoURL} alt="Profile" />
              <span>{profile.displayName}</span>
            </div>

            <div className="AccountBadge-card">
              <Link to={`/users/${profile.uid}`}>
                <div className="AccountBadge-info">
                  <img src={profile.photoURL} alt="Profile" />
                  <div className="AccountBadge-info-text">
                    <h4>{profile.displayName || profile.email}</h4>
                    {profile.displayName ? <h5>{profile.email}</h5> : <span />}
                  </div>
                </div>
              </Link>
              <div className="buttons">
                <Link className="button" to="/new">
                  New Post
                </Link>
                <button
                  type="button"
                  className="button"
                  onClick={firebase.logout}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </li>
    );
  }

  renderNavbarRight() {
    const { firebase, auth, profile } = this.props;
    return isEmpty(auth) ? (
      <React.Fragment>
        <li className="hide-mobile">
          <button
            type="button"
            className="button"
            onClick={() =>
              firebase.login({ provider: 'google', type: 'popup' })
            }
          >
            Sign In
          </button>
        </li>
        <li className="hide-mobile">
          <button
            type="button"
            className="button"
            onClick={() =>
              firebase.login({ provider: 'google', type: 'popup' })
            }
          >
            Sign Up
          </button>
        </li>
        <li>
          <button
            type="button"
            className="button orange"
            onClick={() =>
              firebase.login({ provider: 'google', type: 'popup' })
            }
          >
            Write Post
          </button>
        </li>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Switch>
          <Route key="/new" path="/new" render={() => <span />} />
          <Route
            key="/posts/:postId/edit"
            path="/posts/:postId/edit"
            render={() => <span />}
          />
          <Route
            render={() => (
              <React.Fragment>
                <li>
                  <Link className="button orange" to="/new/">
                    Write
                  </Link>
                </li>
                <li>
                  <Link className="button" to={`/users/${profile.uid}`}>
                    Posts
                  </Link>
                </li>
              </React.Fragment>
            )}
          />
        </Switch>
        {this.renderAccountBadge()}
      </React.Fragment>
    );
  }

  render() {
    const { auth } = this.props;
    return (
      <nav className="Navbar">
        <div className="Navbar-content">
          <ul className="Navbar-left">
            <li>
              <Link to="/">
                <img className="Navbar-logo" src={logoImg} alt="Onward" />
              </Link>
            </li>
            <li className="Navbar-spacer" />
          </ul>
          <Switch>
            <Route key="/new" path="/new" render={navbarCenterRenderFunction} />
            <Route
              key="/posts/:postId/edit"
              path="/posts/:postId/edit"
              render={navbarCenterRenderFunction}
            />
            <Route render={() => <span />} />
          </Switch>
          <ul className="Navbar-right">
            <li className="Navbar-spacer" />
            {!isLoaded(auth) ? (
              <li className="AccountBadge">
                <span className="AccountBadge-empty" />
              </li>
            ) : (
              this.renderNavbarRight()
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default enhance(Navbar);
