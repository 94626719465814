import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PostLink extends Component {
  render() {
    const { edit, post, children, ...elementProps } = this.props;
    const link = `/posts/${post.id}${edit || false ? '/edit' : ''}`;
    return (
      <Link className="PostLink" key={post.id} to={link} {...elementProps}>
        {children || post.title}
      </Link>
    );
  }
}

export default PostLink;
