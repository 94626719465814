import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import { composeDecorators } from 'draft-js-plugins-editor';

// Components
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import createInlineToolbarPlugin, {
  Separator,
} from 'draft-js-inline-toolbar-plugin';
import createAutoListPlugin from 'draft-js-autolist-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createMentionPlugin from 'draft-js-mention-plugin';
import {
  createInlineStyleButton,
  createBlockStyleButton,
} from 'draft-js-buttons';
import ProgressiveImageGrid from '../../components/ProgressiveImageGrid';
import placeDecorator from '../../components/decorators/placeDecorator';
import linkDecorator from '../../components/decorators/linkDecorator';
import PlaceButton from '../../components/PlaceButton';
import LinkButton from '../../components/LinkButton';
import PlaceLink from '../../components/PlaceLink';

// Editor Plugins
import createDndFileUploadPlugin from '../plugins/draft-js-drag-n-drop-upload-plugin';
import keyboardShortcutsPlugin from '../plugins/onward-draft-js-keyboard-shortcuts-plugin';
import createBlockDndPlugin from '../plugins/draft-js-drag-n-drop-plugin';
import createCityPlugin from '../plugins/onward-city-plugin';

// Helper Functions
import moveBlockOrCreateGrid from './moveBlockOrCreateGrid';

const BoldButton = createInlineStyleButton({
  style: 'BOLD',
  children: 'B',
});

const HeadlineOneButton = createBlockStyleButton({
  blockType: 'header-two',
  children: 'H1',
});

const HeadlineTwoButton = createBlockStyleButton({
  blockType: 'header-three',
  children: 'H2',
});

// Create Plugins
const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    toolbarStyles: {
      toolbar: 'inline-toolbar',
    },
    buttonStyles: {
      button: 'inline-toolbar-button',
      buttonWrapper: 'inline-toolbar-button-wrapper',
      active: 'inline-toolbar-button-active',
    },
  },
});
const blockBreakoutPlugin = createBlockBreakoutPlugin();
const autoListPlugin = createAutoListPlugin();
const blockDndPlugin = createBlockDndPlugin({
  onDrop: moveBlockOrCreateGrid,
});
const focusPlugin = createFocusPlugin();
const focusableDraggableDecorator = composeDecorators(
  focusPlugin.decorator,
  blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({
  decorator: focusableDraggableDecorator,
  imageComponent: ProgressiveImageGrid,
});
const linkifyPlugin = createLinkifyPlugin();
const cityPlugin = createCityPlugin({
  decorator: focusableDraggableDecorator,
});

const mentionPlugin = createMentionPlugin({
  supportWhitespace: true,
  entityMutability: 'MUTABLE',
  mentionComponent: mentionProps => (
    <PlaceLink placeId={mentionProps.mention.placeId}>
      {mentionProps.children}
    </PlaceLink>
  ),
});
const { MentionSuggestions } = mentionPlugin;

const generatePlaceholderKey = file => `${file.id}-${file.name}`;

const createPlaceholderImageGrid = (editorState, files) => {
  if (files.length > 3 || files.length < 0) {
    console.Error('Attempted to create an image grid of 4+ images');
    return editorState;
  }

  if (files.length === 0) {
    return editorState;
  }

  return imagePlugin.addImage(editorState, files[0].src, {
    placeholderKey: generatePlaceholderKey(files[0]),
    src2: files.length > 1 ? files[1].src : null,
    placeholderKey2: files.length > 1 ? generatePlaceholderKey(files[1]) : null,
    src3: files.length > 2 ? files[2].src : null,
    placeholderKey3: files.length > 2 ? generatePlaceholderKey(files[2]) : null,
  });
};

const createEnhancedDndFileUploadPlugin = firebase =>
  createDndFileUploadPlugin({
    addPlaceholder(editorState, files) {
      const groupsOfThreeOrLessFiles = [];
      while (files.length) {
        groupsOfThreeOrLessFiles.push(files.splice(0, 3));
      }

      return groupsOfThreeOrLessFiles.reduce(
        createPlaceholderImageGrid,
        editorState
      );
    },
    getPlaceholderBlock(editorState, file) {
      return editorState
        .getCurrentContent()
        .getBlockMap()
        .find(block => {
          const entityKey = block.getEntityAt(0);
          if (entityKey) {
            const entity = editorState.getCurrentContent().getEntity(entityKey);
            const entityData = entity.getData();
            return [
              entityData.placeholderKey,
              entityData.placeholderKey2,
              entityData.placeholderKey3,
            ].includes(generatePlaceholderKey(file));
          }
          return false;
        });
    },
    handleBlock(editorState, placeholderBlock, file) {
      const contentState = editorState.getCurrentContent();
      const entityKey = placeholderBlock.getEntityAt(0);
      const entityData = contentState.getEntity(entityKey).getData();
      let newEntityData = {};
      if (generatePlaceholderKey(file) === entityData.placeholderKey) {
        newEntityData = { servingUrl: file.servingUrl, src: '' };
      } else if (generatePlaceholderKey(file) === entityData.placeholderKey2) {
        newEntityData = { servingUrl2: file.servingUrl, src2: '' };
      } else if (generatePlaceholderKey(file) === entityData.placeholderKey3) {
        newEntityData = { servingUrl3: file.servingUrl, src3: '' };
      }

      const withHostedSrc = contentState.mergeEntityData(
        entityKey,
        newEntityData
      );
      return EditorState.push(editorState, withHostedSrc, 'change-block-data');
    },
    handleProgress(editorState /* , placeholderBlock, percent */) {
      return editorState;
    },
    handleUpload(data, success /* , failed, progress */) {
      Promise.all(
        data.files.map(file => {
          const storagePath = `images/${
            firebase.auth().currentUser.uid
          }/${generatePlaceholderKey(file)}`;
          const uploadTask = firebase.uploadFile(storagePath, file);
          return uploadTask.then(() =>
            fetch(
              `https://outward-d5d5c.appspot.com/?file=${storagePath}/${
                file.name
              }`
            )
              .then(response => response.text())
              .then(servingUrl => {
                file.servingUrl = servingUrl; // eslint-disable-line no-param-reassign
                return file;
              })
          );
        })
      ).then(files => {
        success(files);
      });
    },
  });

const createOnwardPlugins = firebase => [
  cityPlugin,
  inlineToolbarPlugin,
  createEnhancedDndFileUploadPlugin(firebase),
  blockBreakoutPlugin,
  blockDndPlugin,
  autoListPlugin,
  focusPlugin,
  imagePlugin,
  linkifyPlugin,
  mentionPlugin,
  keyboardShortcutsPlugin,
];

const onwardDecorators = [placeDecorator, linkDecorator];

function onwardBlockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  switch (type) {
    case 'unstyled':
      return 'p';
    default:
      break;
  }
  return undefined;
}

const onwardStyleMap = {
  UNDERLINE: {}, // Disables Draft.js default underline style.
};

class OnwardToolbar extends Component {
  render() {
    return (
      <inlineToolbarPlugin.InlineToolbar>
        {externalProps => (
          <React.Fragment>
            <BoldButton {...externalProps} />
            <HeadlineOneButton {...externalProps} />
            <HeadlineTwoButton {...externalProps} />
            <Separator {...externalProps} />
            <PlaceButton {...externalProps} />
            <LinkButton {...externalProps} />
          </React.Fragment>
        )}
      </inlineToolbarPlugin.InlineToolbar>
    );
  }
}

export {
  createOnwardPlugins,
  onwardDecorators,
  onwardBlockStyleFn,
  onwardStyleMap,
  OnwardToolbar,
  MentionSuggestions,
};
