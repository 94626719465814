import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div className="NotFound">
        <div className="NotFound-content">
          <h1>Not Found</h1>
          <p>Try Again</p>
        </div>
      </div>
    );
  }
}
