import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  firebaseConnect,
  firestoreConnect,
  isEmpty,
  isLoaded,
} from 'react-redux-firebase';
import PostCard from '../components/PostCard';
import PostList from '../components/PostList';
import PrefixTextbox from '../components/PrefixTextbox';
import NotFound from './NotFound';

const enhance = compose(
  firebaseConnect(),
  firestoreConnect(({ match }) => [
    match.params.handle
      ? {
          collection: 'users',
          storeAs: 'usersByHandle',
          where: ['handle', '==', match.params.handle],
        }
      : {
          collection: 'users',
          storeAs: 'userById',
          doc: match.params.userId,
        },
  ]),
  connect(({ firestore: { data }, firebase: { profile } }) => ({
    user: data.usersByHandle
      ? data.usersByHandle[Object.keys(data.usersByHandle)]
      : data.userById,
    profile,
  }))
);

class User extends Component {
  render() {
    const { user, profile, match, firebase } = this.props;

    if (!isLoaded(user) || !isLoaded(profile)) {
      return (
        <div className="User">
          <div className="User-info-loading">Loading...</div>
        </div>
      );
    }

    if (isEmpty(user)) {
      return (
        <div className="User">
          <NotFound />
        </div>
      );
    }

    if (match.params.userId && user.handle) {
      // should redirect
      return <Redirect to={`/users/@${user.handle}`} />;
    }

    return (
      <div className="User">
        <div className="User-content">
          <div className="User-info">
            <img
              className="User-profile-photo"
              src={user.photoURL}
              alt={user.displayName}
            />
            <div className="User-info-details">
              <h1>{user.displayName}</h1>
              {user.uid === profile.uid ? (
                <PrefixTextbox
                  prefix="@"
                  prefill={user.handle || ''}
                  onBlur={evt =>
                    firebase.updateProfile({ handle: evt.target.value })
                  }
                  placeholder="Claim your handle..."
                />
              ) : (
                user.handle && (
                  <p>
                    <span>@</span>
                    <span>{user.handle}</span>
                  </p>
                )
              )}
              {user.uid === profile.uid ? (
                <PrefixTextbox
                  prefill={user.bio || ''}
                  onBlur={evt =>
                    firebase.updateProfile({ bio: evt.target.value })
                  }
                  multiline
                  placeholder="Write your story..."
                />
              ) : (
                user.bio && <p>{user.bio}</p>
              )}
            </div>
          </div>
          <h2>{user.uid === profile.uid ? 'Your Posts' : 'Posts'}</h2>
          <PostList
            authorId={user.uid}
            className="PostList-Cards"
            large
            queryId="posts"
            component={PostCard}
          />
          {user.uid === profile.uid && (
            <React.Fragment>
              <h2>Your Drafts</h2>
              <PostList
                authorId={user.uid}
                className="PostList-Cards"
                component={PostCard}
                queryId="drafts"
                drafts
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default enhance(User);
