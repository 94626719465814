import debounce from 'lodash.debounce';
import * as ActionTypes from '../constants/ActionTypes';

const updateEditorStateOnly = editorState => ({
  type: ActionTypes.EDITOR_UPDATE_EDITOR_STATE,
  editorState,
});

const updateHasUploadingImages = hasUploadingImages => ({
  type: ActionTypes.EDITOR_UPDATE_HAS_UPLOADING_IMAGES,
  hasUploadingImages,
});

// Debounced actions:
// https://gist.github.com/krstffr/245fe83885b597aabaf06348220c2fe9
const updateHasUploadingImagesInner = debounce((dispatch, editorState) => {
  const hasUploadingImages = editorState
    .getCurrentContent()
    .getBlockMap()
    .some(block => {
      const entityKey = block.getEntityAt(0);
      if (entityKey) {
        const entity = editorState.getCurrentContent().getEntity(entityKey);
        const entityData = entity.getData();
        return entityData.src || entityData.src2 || entityData.src3;
      }
      return false;
    });
  dispatch(updateHasUploadingImages(hasUploadingImages));
  dispatch(updateEditorStateOnly(editorState));
}, 300);
const debouncedUpdateHasUploadingImages = editorState => dispatch =>
  updateHasUploadingImagesInner(dispatch, editorState);

export const updateEditorState = editorState => dispatch => {
  dispatch(updateEditorStateOnly(editorState));
  dispatch(debouncedUpdateHasUploadingImages(editorState));
};

export const updateTitle = title => ({
  type: ActionTypes.EDITOR_UPDATE_TITLE,
  title,
});

export const clearEditorState = () => ({
  type: ActionTypes.EDITOR_CLEAR_EDITOR_STATE,
});

export const toggleRawState = () => ({
  type: ActionTypes.EDITOR_TOGGLE_RAW_STATE,
});

export const insertCity = () => ({
  type: ActionTypes.EDITOR_INSERT_CITY,
});

export const updateCity = (result, description, entityId) => ({
  type: ActionTypes.EDITOR_UPDATE_CITY,
  result,
  description,
  entityId,
});

export const onLoadFirestoreData = () => ({
  type: ActionTypes.EDITOR_LOAD_FIRESTORE_DATA,
});

export const updateCoverImage = coverImage => ({
  type: ActionTypes.EDITOR_UPDATE_COVER_IMAGE,
  coverImage,
});

export const dropCoverImage = dataUrl => ({
  type: ActionTypes.EDITOR_DROP_COVER_IMAGE,
  dataUrl,
});

export const displayCoverImage = () => ({
  type: ActionTypes.EDITOR_DISPLAY_COVER_IMAGE,
});

export const cleanUpCoverImageDataURL = () => ({
  type: ActionTypes.EDITOR_CLEAN_UP_COVER_IMAGE_DATA_URL,
});

export const updatePlaceTagSuggestions = placeTagSuggestions => ({
  type: ActionTypes.EDITOR_UPDATE_PLACE_TAG_SUGGESTIONS,
  placeTagSuggestions,
});

export const updateAuthor = author => ({
  type: ActionTypes.EDITOR_UPDATE_AUTHOR,
  author,
});

export const addUploadingImage = imageId => ({
  type: ActionTypes.EDITOR_ADD_UPLOADING_IMAGE,
  imageId,
});

export const removeUploadingImage = imageId => ({
  type: ActionTypes.EDITOR_REMOVE_UPLOADING_IMAGE,
  imageId,
});
