import React from 'react';
import { Link } from 'react-router-dom';
// import placeDecorator from '../components/placeDecorator';
import PostCity from '../components/blocks/PostCity';
import ProgressiveImageGrid from '../components/ProgressiveImageGrid';
import PlaceLink from '../components/PlaceLink';

// just a helper to add a <br /> after a block
const addBreaklines = children => children.map(child => [child, <br />]);

/**
 * Define the renderers
 */
const renderers = {
  /**
   * Those callbacks will be called recursively to render a nested structure
   */
  inline: {
    // The key passed here is just an index based on rendering order inside a block
    BOLD: (children, { key }) => <strong key={key}>{children}</strong>,
    ITALIC: (children, { key }) => <em key={key}>{children}</em>,
    CODE: (children, { key }) => <span key={key}>{children}</span>,
  },
  /**
   * Blocks receive children and depth
   * Note that children are an array of blocks with same styling,
   */
  blocks: {
    unstyled: (children, { keys }) =>
      children.map((child, idx) => <p key={keys[idx]}>{child}</p>),
    blockquote: (children, { keys }) => (
      <blockquote key={keys[0]}>{addBreaklines(children)}</blockquote>
    ),
    'header-two': (children, { keys }) =>
      children.map((child, idx) => <h2 key={keys[idx]}>{child}</h2>),
    'header-three': (children, { keys }) =>
      children.map((child, idx) => <h3 key={keys[idx]}>{child}</h3>),
    // You can also access the original keys of the blocks
    'code-block': (children, { keys }) => (
      <pre key={keys[0]}>{addBreaklines(children)}</pre>
    ),
    // or depth for nested lists
    'unordered-list-item': (children, { depth, keys }) => (
      <ul key={keys[keys.length - 1]} className={`ul-level-${depth}`}>
        {children.map(child => (
          <li>{child}</li>
        ))}
      </ul>
    ),
    'ordered-list-item': (children, { depth, keys }) => (
      <ol key={keys.join('|')} className={`ol-level-${depth}`}>
        {children.map((child, index) => (
          <li key={keys[index]}>{child}</li>
        ))}
      </ol>
    ),
    // If your blocks use meta data it can also be accessed like keys
    atomic: (children, { keys, data }) =>
      children.map((child, i) => (
        <div key={keys[i]} {...data[i]}>
          {child}
        </div>
      )),
  },
  /**
   * Entities receive children and the entity data
   */
  entities: {
    // key is the entity key value from raw
    LINK: (children, data, { key }) => (
      <Link key={key} to={data.url}>
        {children}
      </Link>
    ),
    PLACELINK: (children, data, { key }) => (
      <PlaceLink key={key} placeId={data.placeId}>
        {children}
      </PlaceLink>
    ),
    mention: (children, data, { key }) => (
      <PlaceLink key={key} placeId={data.mention.placeId}>
        {children}
      </PlaceLink>
    ),
    IMAGE: (children, data, { key }) => (
      <ProgressiveImageGrid
        key={key}
        alt=""
        servingUrl={data.servingUrl}
        servingUrl2={data.servingUrl2}
        servingUrl3={data.servingUrl3}
        hostedSrc={data.hostedSrc}
        hostedSrc2={data.hostedSrc2}
        hostedSrc3={data.hostedSrc3}
      />
    ),
    CITY: (children, data, { key }) => <PostCity key={key} cityData={data} />,
  },
  /**
   * Array of decorators,
   * Entities receive children and the entity data,
   * inspired by https://facebook.github.io/draft-js/docs/advanced-topics-decorators.html
   * it's also possible to pass a custom Decorator class that matches the [DraftDecoratorType](https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecoratorType.js)
   */
  // decorators: [
  //   {
  //     // by default linkStrategy receives a ContentBlock stub (more info under Creating the ContentBlock)
  //     // strategy only receives first two arguments, contentState is yet not provided
  //     strategy: placeDecorator.strategy,
  //     // component - a callback as with other renderers
  //     // decoratedText a plain string matched by the strategy
  //     // if your decorator depends on draft-js contentState you need to provide convertFromRaw in redraft options
  //     component: ({ children, decoratedText, contentState }) => <a href={decoratedText}>{children}</a>,
  //   },
  // ],
};

export default renderers;
