import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { push } from 'react-router-redux';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import dateFormat from 'dateformat';
import Editor from 'draft-js-plugins-editor';
import unionClassNames from 'union-class-names';
import {
  createOnwardPlugins,
  onwardDecorators,
  onwardBlockStyleFn,
  onwardStyleMap,
  OnwardToolbar,
} from './editorConfig';
import * as EditorActions from '../actions/EditorActions';
import * as ToastActions from '../actions/ToastActions';
import * as ErrorCodes from '../constants/ErrorCodes';
import CityEditor from '../components/editor/CityEditor';
import CoverImageEditor from '../components/editor/CoverImageEditor';
import PlaceTagSuggestions from '../components/editor/PlaceTagSuggestions';

// CSS
import 'draft-js/dist/Draft.css';
import 'draft-js-focus-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';

const enhance = compose(
  withFirebase,
  withFirestore,
  withRouter,
  connect(
    (state, props) => ({
      authExists: !!state.firebase.auth && !!state.firebase.auth.uid,
      profile: state.firebase.profile,
      editorState: state.editor.editorState,
      author: state.editor.author,
      title: state.editor.title,
      postId: props.match.params.postId,
      showRawState: state.editor.showRawState,
      currentUserId: state.firebase.auth.uid,
      isCityEditorOpen: state.cityEditor.isOpen,
      isFirestoreDataLoaded: state.editor.isFirestoreDataLoaded,
    }),
    (dispatch, ownProps) => ({
      onUpdatedEditorState: editorState => {
        dispatch(EditorActions.updateEditorState(editorState));
      },
      onUpdatedTitle: title => {
        dispatch(EditorActions.updateTitle(title));
      },
      onUpdatedCoverImage: coverImage => {
        dispatch(EditorActions.updateCoverImage(coverImage));
      },
      onUpdatedAuthor: author => {
        dispatch(EditorActions.updateAuthor(author));
      },
      onToggleRawState: () => {
        dispatch(EditorActions.toggleRawState());
      },
      onDeleteClick: postId => {
        ownProps.firestore.delete(`/posts/${postId}`);
        dispatch(push('/posts/'));
      },
      clearEditor: () => {
        dispatch(EditorActions.clearEditorState());
        dispatch(EditorActions.onLoadFirestoreData());
      },
      onLoadFirestoreData: () => {
        dispatch(EditorActions.onLoadFirestoreData());
      },
      onNotPostAuthor: () => {
        dispatch(
          ToastActions.showToast('You are not the author of this post.')
        );
      },
    })
  )
);

class OnwardEditor extends Component {
  constructor(props) {
    super(props);
    this.onwardPlugins = createOnwardPlugins(props.firebase);
  }

  componentWillMount() {
    const {
      match,
      firestore,
      onUpdatedEditorState,
      onUpdatedAuthor,
      onUpdatedTitle,
      onUpdatedCoverImage,
      onLoadFirestoreData,
      onNotPostAuthor,
      clearEditor,
      history,
    } = this.props;
    if (match.params.postId) {
      firestore
        .get(`/posts/${match.params.postId}`)
        .then(snapshot => {
          const firebaseData = snapshot.data();
          const { contentState, title, coverImage, author } = firebaseData;
          const editorState = EditorState.createWithContent(
            convertFromRaw(contentState)
          );
          onUpdatedEditorState(editorState);
          onUpdatedTitle(title);
          onUpdatedAuthor(author);
          onUpdatedCoverImage(coverImage);
          onLoadFirestoreData();
        })
        .catch(error => {
          if (error.code === ErrorCodes.FIREBASE_PERMISSION_DENIED) {
            onNotPostAuthor();
            history.push('/');
          }
        });
    } else {
      clearEditor();
    }
  }

  componentDidMount() {
    setTimeout(() => this.focus(), 0);
  }

  componentWillReceiveProps(nextProps) {
    const { isCityEditorOpen } = this.props;
    if (!nextProps.authExists) {
      nextProps.store.dispatch(push('/'));
    }

    if (isCityEditorOpen && !nextProps.isCityEditorOpen) {
      // City editor is about to close
      this.focus();
    }
  }

  componentWillUnmount() {
    const { clearEditor } = this.props;
    clearEditor();
  }

  stopBubblingUp(e) {
    e.stopPropagation();
  }

  focus() {
    this.editor.focus();
  }

  renderButtons(isNewPost) {
    const { onDeleteClick, onToggleRawState, postId } = this.props;
    return (
      <div
        className="buttons"
        onClick={this.stopBubblingUp}
        role="presentation"
      >
        <div className="spacer" />
        <button className="button" type="button" onClick={onToggleRawState}>
          Toggle Raw State
        </button>
        {isNewPost ? (
          <span />
        ) : (
          <button
            className="button red"
            type="button"
            onClick={() => onDeleteClick(postId)}
          >
            Delete Post
          </button>
        )}
      </div>
    );
  }

  render() {
    const {
      editorState,
      isFirestoreDataLoaded,
      author,
      onUpdatedEditorState,
      onUpdatedTitle,
      onNotPostAuthor,
      postId,
      profile,
      showRawState,
      title,
    } = this.props;

    if (author && profile.uid && author !== profile.uid) {
      onNotPostAuthor();
      return <Redirect to="/" />;
    }

    const contentState = editorState.getCurrentContent();
    const isContentLoading = postId && !isFirestoreDataLoaded;
    const isFirstBlockStyled =
      contentState
        .getBlockMap()
        .first()
        .getType() !== 'unstyled';
    const isNewPost = postId === undefined;
    const hasNoText = !contentState.hasText();

    let className = 'Editor';
    if (!contentState.hasText()) {
      if (isContentLoading || isFirstBlockStyled) {
        className += ' Editor-hidePlaceholder';
      }
    }

    const rawState = JSON.stringify(convertToRaw(contentState), null, 2);
    return (
      <div
        className="Editor-wrapper"
        onMouseDown={this.focus.bind(this)}
        role="presentation"
      >
        <div
          className={unionClassNames(
            'content',
            isContentLoading ? 'content-loading' : ''
          )}
        >
          <div className={className}>
            <CoverImageEditor isContentLoading={isContentLoading} />
            <h1>
              <input
                value={title}
                placeholder="Title..."
                onChange={e => onUpdatedTitle(e.target.value)}
              />
            </h1>
            <div
              className={`PostMetadata${
                isNewPost && hasNoText ? ' empty' : ''
              }`}
            >
              <img src={profile.photoURL} alt="Profile" />
              <h4>{profile.displayName}</h4>
              <h4>{dateFormat(new Date(), 'mmmm d, yyyy')}</h4>
            </div>
            <Editor
              editorState={editorState}
              onChange={onUpdatedEditorState}
              ref={c => {
                this.editor = c;
              }}
              decorators={onwardDecorators}
              placeholder="Tell a story..."
              spellCheck
              customStyleMap={onwardStyleMap}
              blockStyleFn={onwardBlockStyleFn}
              plugins={this.onwardPlugins}
            />
            <PlaceTagSuggestions />
            <OnwardToolbar />
            <CityEditor />
          </div>
          <div className="Editor-toolbar">{this.renderButtons(isNewPost)}</div>
          {showRawState ? (
            <pre
              className="State"
              style={{ marginTop: '1rem' }}
              dangerouslySetInnerHTML={{ __html: rawState }}
            />
          ) : (
            <span />
          )}
        </div>
      </div>
    );
  }
}

export default enhance(OnwardEditor);
