import * as ActionTypes from '../constants/ActionTypes';

export const openCityEditor = (entityId, cityData, elementRect) => ({
  type: ActionTypes.CITY_OPEN_EDITOR,
  entityId,
  cityData,
  elementRect,
});

export const closeCityEditor = () => ({
  type: ActionTypes.CITY_CLOSE_EDITOR,
});

export const updateTitle = title => ({
  type: ActionTypes.CITY_UPDATE_DATA,
  title,
});
