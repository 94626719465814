import City from '../../components/blocks/City';

export default function createCityPlugin(config = {}) {
  let Component = City;
  if (config.decorator) {
    Component = config.decorator(City);
  }
  return {
    blockRendererFn(block) {
      if (block.getType() === 'atomic') {
        return {
          component: Component,
          editable: false,
        };
      }
      return null;
    },
  };
}
