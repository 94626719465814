import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

import * as EditorActions from '../../actions/EditorActions';
import { MentionSuggestions } from '../../containers/editorConfig';
import PlaceTagSuggestionsEntry from './PlaceTagSuggestionsEntry';

// transform snake_case to camelCase
const formattedSuggestion = sf => ({
  mainText: sf.main_text,
  secondaryText: sf.secondary_text,
});

const enhance = compose(
  connect(
    state => ({
      placeTagSuggestions: state.editor.placeTagSuggestions,
    }),
    dispatch => ({
      onAutocompleteCallback: predictions => {
        if (predictions.length > 0) {
          dispatch(
            EditorActions.updatePlaceTagSuggestions(
              predictions.map(p => ({
                // id: p.id,
                // description: p.description,
                // placeId: p.place_id,
                // index: idx,
                // matchedSubstrings: p.matched_substrings,
                // terms: p.terms,
                // types: p.types,
                placeId: p.place_id,
                name: formattedSuggestion(p.structured_formatting).mainText,
                formattedSuggestion: formattedSuggestion(
                  p.structured_formatting
                ),
              }))
            )
          );
        }
      },
    })
  )
);

class PlaceTagSuggestions extends Component {
  constructor(props) {
    super(props);

    this.debouncedFetchPredictions = debounce(this.fetchPredictions, 200).bind(
      this
    );
  }

  componentDidMount() {
    this.init();
  }

  onError(status) {
    console.error(
      '[react-places-autocomplete]: error happened when fetching data from Google Maps API.\nPlease check the docs here (https://developers.google.com/maps/documentation/javascript/places#place_details_responses)\nStatus: ',
      status
    );
  }

  fetchPredictions({ value }) {
    const { onAutocompleteCallback } = this.props;
    if (value.length) {
      this.autocompleteService.getPlacePredictions(
        {
          input: value,
        },
        onAutocompleteCallback
      );
    }
  }

  init() {
    if (!window.google) {
      throw new Error(
        '[react-places-autocomplete]: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    if (!window.google.maps.places) {
      throw new Error(
        '[react-places-autocomplete]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
      );
    }

    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    this.autocompleteOK = window.google.maps.places.PlacesServiceStatus.OK;
  }

  render() {
    const { placeTagSuggestions } = this.props;
    return (
      <div className="PlaceTagSuggestionsWrapper">
        <MentionSuggestions
          onSearchChange={this.debouncedFetchPredictions}
          suggestions={placeTagSuggestions}
          entryComponent={PlaceTagSuggestionsEntry}
        />
      </div>
    );
  }
}

export default enhance(PlaceTagSuggestions);
