import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import PostCard from './PostCard';
import PostList from './PostList';

const enhance = compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'users',
      doc: props.authorId,
    },
  ]),
  connect(({ firestore: { data } }, props) => ({
    user: data.users ? data.users[props.authorId] : null,
  }))
);

class PostRelatedContent extends Component {
  render() {
    const { authorId, postId, user } = this.props;
    return (
      <div className="PostRelatedContent">
        {user == null ? (
          <div>Loading...</div>
        ) : (
          <React.Fragment>
            <h2>
              <img
                className="PostRelatedContent-profile"
                src={user.photoURL}
                alt={user.displayName}
              />
              <span>More by&nbsp;</span>
              <span>{user.displayName}</span>
            </h2>
            <PostList
              className="PostList-Cards"
              component={PostCard}
              authorId={authorId}
              excludePostId={postId}
              limit={2}
              queryId="abc"
            />
            <h2>Related</h2>
            <PostList
              className="PostList-Cards"
              component={PostCard}
              excludeAuthorId={authorId}
              excludePostId={postId}
              limit={4}
              queryId="def"
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default enhance(PostRelatedContent);
