import React, { Component } from 'react';
import unionClassNames from 'union-class-names';

class PrefixTextbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prefixWidth: 0,
      value: props.prefill,
      isFocused: false,
    };

    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidMount() {
    this.setState({
      prefixWidth: this.prefix.offsetWidth,
    });
  }

  onChange(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      value: event.target.value,
    });
  }

  handleBlur(event) {
    const { onBlur } = this.props;
    this.setState({
      isFocused: false,
    });
    onBlur(event);
  }

  handleFocus() {
    this.setState({
      isFocused: true,
    });
  }

  render() {
    const { className, prefix, onBlur, multiline, ...inputProps } = this.props;
    const { value, prefixWidth, isFocused } = this.state;
    const classNames = unionClassNames(
      'PrefixTextbox',
      value ? 'with-text' : '',
      className
    );
    const isDisplayingPrefix = isFocused || value;
    const elementProps = {
      onChange: this.onChange,
      className: 'text-input',
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      value,
      style: isDisplayingPrefix
        ? {
            transform: `translate3d(${2 + prefixWidth}px, 0, 0)`,
            paddingRight: 18 + prefixWidth,
          }
        : {},
      ...inputProps,
    };
    return (
      <React.Fragment>
        <span className={classNames}>
          {multiline ? (
            <textarea id="prefix-input" {...elementProps} />
          ) : (
            <input id="prefix-input" {...elementProps} />
          )}
          <span
            ref={c => {
              this.prefix = c;
            }}
          >
            {prefix}
          </span>
        </span>
      </React.Fragment>
    );
  }
}

export default PrefixTextbox;
