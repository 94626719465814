import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.657 3L4 15.657M20.657 7L8 19.657M4 15.657v4M8 19.657H4M12.828 7l3.829 4M16.828 3l3.829 4"
      />
    </g>
  </svg>
);
