import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import unionClassNames from 'union-class-names';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { showToast } from '../../actions/ToastActions';

const enhance = compose(
  withFirebase,
  withFirestore,
  connect(
    (state, props) => ({
      editorState: state.editor.editorState,
      postId: props.postId,
      hasUploadingImages: state.editor.hasUploadingImages,
      isCoverImageUploading: state.editor.coverImage.isUploading,
    }),
    dispatch => ({
      onPublish: postId => {
        dispatch(showToast('Published.', 'View', `/posts/${postId}`));
      },
    })
  )
);

class PublishPostButton extends Component {
  publishPost() {
    const { firestore, postId, onPublish } = this.props;

    const query = firestore.update(`posts/${postId}`, {
      published: true,
    });

    return query.then(docRef => {
      const id = postId || docRef.id;
      onPublish(id);
    });
  }

  render() {
    const {
      editorState,
      isCoverImageUploading,
      hasUploadingImages,
    } = this.props;
    const contentState = editorState.getCurrentContent();
    const hasNoText = !contentState.hasText();
    const isDisabled = hasNoText || isCoverImageUploading || hasUploadingImages;
    const classNames = unionClassNames(
      'button green',
      isDisabled ? 'button-disabled' : ''
    );
    const onClick = isDisabled ? () => {} : () => this.publishPost();
    return (
      <li>
        <button type="button" className={classNames} onClick={onClick}>
          Publish
        </button>
      </li>
    );
  }
}

export default enhance(PublishPostButton);
