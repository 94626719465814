import PlaceLink from '../PlaceLink';

function findPlaceLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'PLACELINK'
    );
  }, callback);
}

export default {
  strategy: findPlaceLinkEntities,
  component: PlaceLink,
};
