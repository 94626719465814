import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  firebaseConnect,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { convertFromRaw } from 'draft-js';
import { Link, Redirect } from 'react-router-dom';
import redraft from 'redraft';
import dateFormat from 'dateformat';
import renderers from './postRendererConfig';
import PencilIcon from '../icons/pencil.svg';
// import SaveIcon from '../icons/save.svg';
// import PlusIcon from '../icons/plus.svg';
// import CoffeeIcon from '../icons/coffee.svg';
// import ShareIcon from '../icons/share.svg';
import PostRelatedContent from '../components/PostRelatedContent';
import PostAuthor from '../components/PostAuthor';
import HomePageBanner from '../components/HomePageBanner';
import * as ErrorCodes from '../constants/ErrorCodes';
import * as ToastActions from '../actions/ToastActions';

const enhance = compose(
  firebaseConnect(),
  firestoreConnect(props => [
    { collection: 'posts', doc: props.match.params.postId },
  ]),
  connect(
    ({ firestore: { data, errors }, firebase: { profile } }, props) => ({
      postId: props.match.params.postId,
      post: data.posts && data.posts[props.match.params.postId],
      errors,
      profile,
    }),
    dispatch => ({
      onPermissionDenied: () => {
        dispatch(
          ToastActions.showToast("You don't have permission to view this post.")
        );
      },
    })
  )
);

class Post extends Component {
  render() {
    const { post, postId, errors, onPermissionDenied, profile } = this.props;
    if (errors.allIds.length > 0) {
      const error = errors.byQuery[errors.allIds[0]];
      if (error.code === ErrorCodes.FIREBASE_PERMISSION_DENIED) {
        onPermissionDenied();
        return <Redirect to="/" />;
      }
    }

    if (!isLoaded(post)) {
      return <span />;
    }

    const rendered = redraft(post.contentState, renderers, {
      convertFromRaw,
    });
    // redraft returns a null if there's nothing to render
    if (!rendered) {
      return <span>Nothing to Render</span>;
    }

    return (
      <div className="Post">
        <div className="Post-cover-image">
          {post.coverImage.url && <img src={post.coverImage.url} alt="Hero" />}
          {post.author === profile.uid && (
            <Link
              className="Post-edit-button icon-button"
              to={`/posts/${postId}/edit`}
            >
              <PencilIcon />
              <span>Edit</span>
            </Link>
          )}
        </div>
        <article>
          <h1>{post.title}</h1>
          <div className="PostMetadata">
            <PostAuthor authorId={post.author} />
            <h4 className="date">
              {dateFormat(post.dateCreated, 'mmmm d, yyyy')}
            </h4>
            {/* <span className="icon-button">
              <SaveIcon />
            </span>
            <span className="icon-button">
              <ShareIcon />
            </span> */}
          </div>
          <div className="Post-content">{rendered}</div>
          <div className="buttons">
            {/* <span className="icon-button">
              <SaveIcon /> Add to Collection
            </span>
            <span className="icon-button">
              <PlusIcon /> Follow DAN
            </span>
            <span className="icon-button">
              <CoffeeIcon /> Send Tip
            </span>
            <span className="spacer" />
            <span className="icon-button">
              <ShareIcon /> Share
            </span> */}
          </div>
        </article>

        <PostRelatedContent authorId={post.author} postId={postId} />
        <HomePageBanner />
      </div>
    );
  }
}

export default enhance(Post);
