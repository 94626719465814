import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  firebaseConnect,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import unionClassNames from 'union-class-names';
import PostLink from './PostLink';

const buildQueriesFromProps = props => {
  const { authorId, excludePostId, excludeAuthorId, drafts, limit } = props;
  const queries = [];
  const whereClauses = [];

  const query = {
    collection: 'posts',
    storeAs: props.queryId || 'posts',
    orderBy: authorId
      ? ['dateCreated', 'desc']
      : [['author', 'asc'], ['dateCreated', 'desc']],
  };
  if (limit) {
    // Limit should be (limit + 1) if we may have a postId to deal with
    query.limit = excludePostId ? limit + 1 : limit;
  }

  whereClauses.push(['published', '==', !drafts]);
  if (authorId) {
    whereClauses.push(['author', '==', authorId]);
  }

  // Here, if excludeAuthorId is provided, we need to split into two different
  // queries, with two different where clauses.
  if (excludeAuthorId) {
    const lessThanWhereClauses = whereClauses.slice();
    const lessThanQuery = Object.assign({}, query);
    lessThanWhereClauses.push(['author', '<', excludeAuthorId]);
    lessThanQuery.where = lessThanWhereClauses;
    lessThanQuery.storeAs = `${props.queryId}-less`;
    queries.push(lessThanQuery);

    const greaterThanWhereClauses = whereClauses.slice();
    const greaterThanQuery = Object.assign({}, query);
    greaterThanWhereClauses.push(['author', '>', excludeAuthorId]);
    greaterThanQuery.where = greaterThanWhereClauses;
    greaterThanQuery.storeAs = `${props.queryId}-more`;
    queries.push(greaterThanQuery);
  } else {
    query.where = whereClauses;
    queries.push(query);
  }

  return queries;
};

const enhance = compose(
  firebaseConnect(),
  firestoreConnect(props => buildQueriesFromProps(props)),
  connect(({ firestore }, props) => ({
    posts: Array.concat(
      firestore.ordered[props.queryId || 'posts'] || [],
      firestore.ordered[`${props.queryId || 'posts'}-less`] || [],
      firestore.ordered[`${props.queryId || 'posts'}-more`] || []
    ),
  }))
);

class PostList extends Component {
  renderPosts(posts) {
    const { limit, component, excludePostId, large } = this.props;
    const ItemComponent = component || PostLink;
    // console.log(limit, posts)
    const filteredPosts = posts.filter(post => post.id !== excludePostId);
    const limitedPosts = limit ? filteredPosts.slice(0, limit) : posts;
    // if (queryId === "def")
    //   console.log(queryId + ":", this.props, firestore.ordered, posts, filteredPosts, limitedPosts)
    return limitedPosts.map(post => (
      <ItemComponent key={post.id} post={post} large={large} />
    ));
  }

  render() {
    const { posts, className, large } = this.props;
    const classNames = unionClassNames(
      `PostList${large ? ' large' : ''}`,
      className
    );
    return (
      <ul className={classNames}>
        {!isLoaded(posts) ? (
          <div className="PostList-loading">Loading...</div>
        ) : (
          this.renderPosts(posts)
        )}
      </ul>
    );
  }
}

export default enhance(PostList);
