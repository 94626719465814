import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="Footer">
        <h4>Onward</h4>
        <div className="spacer" />
        <h5 className="links">
          <span>©</span>
          <span>{year}</span>
          {/* <span className="mdot">·</span>
          About
          <span className="mdot">·</span>
          Partnerships
          <span className="mdot">·</span>
          Support */}
        </h5>
      </footer>
    );
  }
}

export default Footer;
