import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import unionClassNames from 'union-class-names';
import Dropzone from 'react-dropzone';
import PlusIcon from '../../icons/plus.svg';
import * as EditorActions from '../../actions/EditorActions';

const generatePlaceholderKey = file => `${file.id}-${file.name}`;

const enhance = compose(
  withFirebase,
  connect(
    state => ({
      coverImage: state.editor.coverImage,
    }),
    (dispatch, ownProps) => ({
      onDrop: acceptedFiles => {
        if (acceptedFiles) {
          Promise.all(
            acceptedFiles.map(file => {
              const dataUrl = URL.createObjectURL(file);
              dispatch(EditorActions.dropCoverImage(dataUrl));

              const storagePath = `images/${
                ownProps.firebase.auth().currentUser.uid
              }/${generatePlaceholderKey(file)}`;
              const uploadTask = ownProps.firebase.uploadFile(
                storagePath,
                file
              );
              return uploadTask.then(result => {
                result.uploadTaskSnaphot.ref
                  .getDownloadURL()
                  .then(downloadURL => {
                    dispatch(
                      EditorActions.updateCoverImage({
                        url: downloadURL,
                      })
                    );
                  });
                fetch(
                  `https://outward-d5d5c.appspot.com/?file=${storagePath}/${
                    file.name
                  }`
                )
                  .then(response => response.text())
                  .then(servingUrl => {
                    dispatch(
                      EditorActions.updateCoverImage({
                        servingUrl,
                      })
                    );
                  });
              });
            })
          );
        }

        // Rejected files? Show toast?
      },

      onPreviewLoad: e => {
        const img = e.target;
        const { width, height } = img;
        dispatch(
          EditorActions.updateCoverImage({
            width,
            height,
          })
        );
      },

      onFullLoad: isUploading => {
        if (isUploading) {
          dispatch(EditorActions.displayCoverImage());
          setTimeout(() => {
            dispatch(EditorActions.cleanUpCoverImageDataURL());
          }, 500);
        }
      },
    })
  )
);

class CoverImageEditor extends React.Component {
  render() {
    const { coverImage, onDrop, onPreviewLoad, onFullLoad } = this.props;
    const style =
      coverImage.width && coverImage.height
        ? {
            paddingBottom: `${(
              (coverImage.height / coverImage.width) *
              100
            ).toString()}%`,
          }
        : {};
    let statusClassName = '';
    if (coverImage.url || coverImage.dataUrl) {
      statusClassName = coverImage.isUploading
        ? 'display-preview'
        : 'display-full';
    }
    const className = unionClassNames('CoverImageEditor', statusClassName);
    return (
      <div className={className} style={style}>
        {coverImage.url || coverImage.dataUrl ? (
          <React.Fragment>
            {coverImage.dataUrl && (
              <div className="cover-preview">
                <h4>Loading...</h4>
                <img
                  ref={c => {
                    this.coverPreview = c;
                  }}
                  onLoad={onPreviewLoad}
                  src={coverImage.dataUrl}
                  alt="Cover Preview"
                />
              </div>
            )}
            {coverImage.url && (
              <div className="cover-full">
                <img
                  ref={c => {
                    this.coverFull = c;
                  }}
                  onLoad={() => onFullLoad(coverImage.isUploading)}
                  src={coverImage.url}
                  alt="Cover"
                />
              </div>
            )}
          </React.Fragment>
        ) : (
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={unionClassNames(
                  'dropzone',
                  isDragActive ? 'dropzone--isActive' : ''
                )}
              >
                <input {...getInputProps()} />
                <PlusIcon />
                <h4>
                  <span className="default">Add Image</span>
                  <span className="dragging">Drop to Upload</span>
                </h4>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

export default enhance(CoverImageEditor);
