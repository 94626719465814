import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { push } from 'react-router-redux';
import unionClassNames from 'union-class-names';
import { convertToRaw } from 'draft-js';
import Mousetrap from 'mousetrap';
import { showToast } from '../../actions/ToastActions';

const enhance = compose(
  withFirebase,
  withFirestore,
  connect(
    (state, props) => ({
      coverImage: state.editor.coverImage,
      editorState: state.editor.editorState,
      title: state.editor.title,
      postId: props.postId,
      currentUserId: state.firebase.auth.uid,
      hasUploadingImages: state.editor.hasUploadingImages,
      isCoverImageUploading: state.editor.coverImage.isUploading,
    }),
    dispatch => ({
      updatePathAsNeeded: postId => {
        dispatch(push(`/posts/${postId}/edit`));
      },
      onSave: postId => {
        dispatch(showToast('Post Saved.', 'View', `/posts/${postId}`));
      },
    })
  )
);

class SavePostButton extends Component {
  constructor(props) {
    super(props);
    this.onSaveKeyboardShortcut = this.onSaveKeyboardShortcut.bind(this);
  }

  componentDidMount() {
    document.addEventListener('onward-save', this.onSaveKeyboardShortcut);
    Mousetrap.bind('mod+s', this.onSave);
  }

  componentWillUnmount() {
    Mousetrap.unbind('mod+s');
    document.removeEventListener('onward-save', this.onSaveKeyboardShortcut);
  }

  onSaveKeyboardShortcut(e) {
    e.preventDefault();
    if (!this.isDisabled()) {
      this.savePost();
    }
  }

  isDisabled() {
    const {
      editorState,
      isCoverImageUploading,
      hasUploadingImages,
    } = this.props;
    const contentState = editorState.getCurrentContent();
    const hasNoText = !contentState.hasText();
    return hasNoText || isCoverImageUploading || hasUploadingImages;
  }

  savePost(callback) {
    const {
      firestore,
      editorState,
      coverImage,
      currentUserId,
      postId,
      updatePathAsNeeded,
      onSave,
      title,
    } = this.props;
    const currentContent = editorState.getCurrentContent();
    const postTitle = title || 'Untitled Post';
    const rawContentState = convertToRaw(currentContent);
    const onComplete = callback || updatePathAsNeeded;

    let query;
    let payload = {
      title: postTitle,
      contentState: rawContentState,
      coverImage: {
        url: coverImage.url || null,
        width: coverImage.width || null,
        height: coverImage.height || null,
        servingUrl: coverImage.servingUrl || null,
      },
    };

    if (postId) {
      query = firestore.update(`posts/${postId}`, payload);
    } else {
      payload = Object.assign(payload, {
        author: currentUserId,
        dateCreated: Date.now(),
        published: false,
      });
      query = firestore.add('posts', payload);
    }

    return query.then(docRef => {
      const id = postId || docRef.id;
      onSave(id);
      onComplete(id);
    });
  }

  render() {
    const classNames = unionClassNames(
      'button',
      this.isDisabled() ? 'button-disabled' : ''
    );
    const onClick = this.isDisabled() ? () => {} : () => this.savePost();
    return (
      <li>
        <button type="button" className={classNames} onClick={onClick}>
          Save
        </button>
      </li>
    );
  }
}

export default enhance(SavePostButton);
