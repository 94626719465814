import React, { Component } from 'react';
import { EditorState, RichUtils } from 'draft-js';
import unionClassNames from 'union-class-names';
import LinkIcon from '../icons/link.svg';

const preventBubblingUp = event => {
  event.preventDefault();
  event.stopPropagation();
};

class LinkPasteBox extends Component {
  constructor(props) {
    super(props);
    this.state = { url: '' };
    this.onChange = e => this.setState({ url: e.target.value });
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
    this.textInput.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onKeyUp(event) {
    const { url } = this.state;
    const { getEditorState, setEditorState } = this.props;
    if (event.keyCode === 13) {
      // enter
      const editorState = getEditorState();
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      setEditorState(
        RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        )
      );

      this.onWindowClick();
    }
  }

  onWindowClick() {
    const { onOverrideContent } = this.props;
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    onOverrideContent(undefined);
  }

  render() {
    const { theme } = this.props;
    const { url } = this.state;
    const classNames = unionClassNames(
      theme.buttonWrapper,
      'LinkButton-wrapper'
    );

    return (
      <div
        className={classNames}
        onMouseDown={preventBubblingUp}
        role="presentation"
      >
        <LinkIcon />
        <input
          value={url}
          onKeyUp={this.onKeyUp}
          onChange={this.onChange}
          placeholder="http://..."
          onClick={preventBubblingUp}
          ref={input => {
            this.textInput = input;
          }}
        />
      </div>
    );
  }
}

export default LinkPasteBox;
