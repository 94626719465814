import React, { Component } from 'react';
import unionClassNames from 'union-class-names';
import 'draft-js-focus-plugin/lib/plugin.css';

class ProgressiveImageGrid extends Component {
  generateImageUrl(servingUrl, size) {
    return `${servingUrl}=s${size}`;
  }

  renderImage(
    placeholderSrc,
    hostedSrc,
    servingUrl,
    elementProps,
    alt,
    classNames,
    size
  ) {
    if (!placeholderSrc && !hostedSrc && !servingUrl) {
      return null;
    }

    const cn = unionClassNames(
      classNames,
      placeholderSrc ? 'display-preview' : 'display-full'
    );

    let src;
    if (servingUrl) {
      src = this.generateImageUrl(servingUrl, size);
    } else {
      src = placeholderSrc || hostedSrc;
    }

    return (
      <figure className={cn} role="presentation">
        <img src={src} {...elementProps} alt={alt} />
      </figure>
    );
  }

  render() {
    const { block, alt, className, ...otherProps } = this.props;

    // leveraging destructuring to omit certain properties from props
    const {
      blockProps, // eslint-disable-line no-unused-vars
      blockStyleFn, // eslint-disable-line no-unused-vars
      customStyleMap, // eslint-disable-line no-unused-vars
      customStyleFn, // eslint-disable-line no-unused-vars
      decorator, // eslint-disable-line no-unused-vars
      forceSelection, // eslint-disable-line no-unused-vars
      offsetKey, // eslint-disable-line no-unused-vars
      selection, // eslint-disable-line no-unused-vars
      tree, // eslint-disable-line no-unused-vars
      theme, // eslint-disable-line no-unused-vars
      contentState,
      hostedSrc, // Firebase-hosted URL
      hostedSrc2, // Firebase-hosted URL
      hostedSrc3, // Firebase-hosted URL
      servingUrl, // Google-hosted dynamic serving URL
      servingUrl2, // Google-hosted dynamic serving URL
      servingUrl3, // Google-hosted dynamic serving URL
      ...elementProps
    } = otherProps;

    const entityData = contentState
      ? contentState.getEntity(block.getEntityAt(0)).getData()
      : {};

    const classNames = unionClassNames('ProgressiveImage', className);
    let size = 1440; // 2x 1-wide max content width;
    if (entityData.servingUrl3 || servingUrl3) {
      size = 460; // 2x 3-wide image
    } else if (entityData.servingUrl2 || servingUrl2) {
      size = 700; // 2x 2-wide image
    }

    return (
      <div className="ProgressiveImageGrid">
        {this.renderImage(
          entityData.src,
          entityData.hostedSrc || hostedSrc,
          entityData.servingUrl || servingUrl,
          elementProps,
          alt,
          classNames,
          size
        )}
        {this.renderImage(
          entityData.src2,
          entityData.hostedSrc2 || hostedSrc2,
          entityData.servingUrl2 || servingUrl2,
          elementProps,
          alt,
          classNames,
          size
        )}
        {this.renderImage(
          entityData.src3,
          entityData.hostedSrc3 || hostedSrc3,
          entityData.servingUrl3 || servingUrl3,
          elementProps,
          alt,
          classNames,
          size
        )}
      </div>
    );
  }
}

export default ProgressiveImageGrid;
