import React, { Component } from 'react';

class PlaceLink extends Component {
  render() {
    const { children, contentState, placeId, entityKey } = this.props;
    const entityData = contentState
      ? contentState.getEntity(entityKey).getData()
      : null;
    const url = `https://www.google.com/maps/place/?q=place_id:${placeId ||
      entityData.placeid}`;
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="place-link"
      >
        {children}
      </a>
    );
  }
}

export default PlaceLink;
