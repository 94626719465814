import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import bannerSignedOut from '../img/banner-signed-out@2x.jpg';
import bannerSignedIn from '../img/banner-signed-in@2x.jpg';

const enhance = compose(
  firebaseConnect(),
  connect(({ firebase: { auth } }) => ({ auth }))
);

class Navbar extends Component {
  render() {
    const { firebase, auth } = this.props;
    if (!isLoaded(auth)) {
      return <div>Loading banner...</div>;
    }
    return isEmpty(auth) ? (
      <div
        className="HomePageBanner signed-out"
        style={{
          backgroundImage: `url(${bannerSignedOut})`,
        }}
      >
        <div className="HomePageBanner-text-content">
          <h1>Travel stories, guides, and itineraries from people like you.</h1>
          <h3>
            <span>Share your story and fund your next trip.&nbsp;</span>
            <button
              type="button"
              className="text-button with-background"
              onClick={() =>
                firebase.login({ provider: 'google', type: 'popup' })
              }
            >
              Write Post
            </button>
          </h3>
        </div>
      </div>
    ) : (
      <div
        className="HomePageBanner signed-in"
        style={{
          backgroundImage: `url(${bannerSignedIn})`,
        }}
      >
        <div className="HomePageBanner-text-content">
          <h1>Share your travel story.</h1>
          <h3>
            <Link className="text-button with-background" to="/new">
              Write Post
            </Link>
          </h3>
        </div>
      </div>
    );
  }
}

export default enhance(Navbar);
