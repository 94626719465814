import createHistory from 'history/createBrowserHistory';
import en from 'javascript-time-ago/locale/en';
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import TimeAgo from 'javascript-time-ago';

import { applyMiddleware, compose, createStore } from 'redux';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import { omit } from 'lodash';
import { defaultConfig as reactReduxFirebaseConfigDefaults } from 'react-redux-firebase/src/constants';
import { Provider } from 'react-redux';
import { reactReduxFirebase } from 'react-redux-firebase';
import { reduxFirestore } from 'redux-firestore';

import App from './containers/App';
import initialState from './initialState';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './reducers';

import 'babel-polyfill';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import './styles/index.scss';

// Configure TimeAgo
TimeAgo.locale(en);

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  profileFactory: userData => {
    // how profiles are stored in database
    // Convert to JSON format (to prevent issue of writing invalid type to Firestore)
    const userDataObject = userData.toJSON ? userData.toJSON() : userData;
    // Remove unnecessary auth params (configurable) and preserve types of timestamps
    return {
      ...omit(
        userDataObject,
        reactReduxFirebaseConfigDefaults.keysToRemoveFromAuth
      ),
      uid: userDataObject.uid,
      avatarUrl: userDataObject.photoURL, // match profile pattern used for RTDB
    };
  },
  logErrors: true,
  enableLogging: true,
  logListenerError: true,
};

// Firebase config
const firebaseConfig = {
  apiKey: 'AIzaSyCgVzNk7QwDmpsov75CFkIElN4MuUiw9KY',
  authDomain: 'onward.to',
  databaseURL: 'https://outward-d5d5c.firebaseio.com',
  projectId: 'outward-d5d5c',
  storageBucket: 'outward-d5d5c.appspot.com',
  messagingSenderId: '514772210277',
};
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const firestoreSettings = {
  timestampsInSnapshots: true,
};
firestore.settings(firestoreSettings);

// Add BOTH store enhancers when making store creator
const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig),
  reduxFirestore(firebase, {
    allowMultipleListeners: listenerSettings =>
      // return whether or not multiple listeners should be enabled in this case
      !!listenerSettings.storeAs, // allow multiple listeners for queries containing storeAs
  })
)(createStore);

const history = createHistory();
const middleware = applyMiddleware(
  routerMiddleware(history),
  thunkMiddleware
  // createLogger()
);

const store = createStoreWithFirebase(rootReducer, initialState, middleware);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
