import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import unionClassNames from 'union-class-names';
import { withFirebase, firestoreConnect, isLoaded } from 'react-redux-firebase';
import PostLink from './PostLink';
import PencilIcon from '../icons/pencil.svg';

const enhance = compose(
  withFirebase,
  firestoreConnect(({ post }) => [{ collection: 'users', doc: post.author }]),
  connect(({ firestore: { data }, firebase: { profile } }, { post }) => ({
    author: data.users && data.users[post.author],
    profile,
  }))
);

class PostCard extends Component {
  render() {
    const { post, author, edit, profile, large } = this.props;
    const { url, servingUrl } = post.coverImage;
    console.log(post.coverImage);
    const hasImage = !!(url || servingUrl);
    const imageUrl = servingUrl ? `${servingUrl}=s384-c` : url;
    return (
      <PostLink post={post} edit={edit}>
        <div className={unionClassNames('PostCard', large ? 'large' : '')}>
          {hasImage && (
            <div
              className="image"
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            />
          )}
          <div className="details">
            <h3>{post.title}</h3>
            {isLoaded(author) ? (
              <div className="author">
                <img src={author.photoURL} alt="Profile" />
                <h4>{author.displayName}</h4>
              </div>
            ) : (
              <span />
            )}
            {post.author === profile.uid && (
              <Link
                className="Post-edit-button icon-button"
                to={`/posts/${post.id}/edit`}
              >
                <PencilIcon />
              </Link>
            )}
          </div>
        </div>
      </PostLink>
    );
  }
}

export default enhance(PostCard);
