import React, { Component } from 'react';
import unionClassNames from 'union-class-names';
import PinIcon from '../icons/pin.svg';
import PlaceSearchBox from './PlaceSearchBox';

const preventBubblingUp = event => {
  event.preventDefault();
  event.stopPropagation();
};

class PlaceButton extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    const { onOverrideContent } = this.props;
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    event.preventDefault();
    onOverrideContent(PlaceSearchBox);
  }

  render() {
    const { theme } = this.props;
    return (
      <div
        className={unionClassNames('PlaceButton', theme.buttonWrapper)}
        role="presentation"
        onMouseDown={preventBubblingUp}
      >
        <button type="button" onClick={this.onClick} className={theme.button}>
          <PinIcon />
        </button>
      </div>
    );
  }
}

export default PlaceButton;
