import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';

const enhance = compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'users',
      doc: props.authorId,
    },
  ]),
  connect(({ firestore: { data } }, props) => ({
    user: data.users ? data.users[props.authorId] : null,
  }))
);

class PostAuthor extends Component {
  render() {
    const { user, authorId } = this.props;
    return (
      <div className="PostAuthor">
        {user && (
          <Link
            to={user.handle ? `/users/@${user.handle}` : `/users/${authorId}`}
          >
            <img
              className="PostAuthor-profile"
              src={user.photoURL}
              alt={user.displayName}
            />
            <h4>{user.displayName}</h4>
          </Link>
        )}
      </div>
    );
  }
}

export default enhance(PostAuthor);
