import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as CityActions from '../../actions/CityActions';
import CityMap from './CityMap';
import { SF_DESCRIPTION } from '../../constants/Strings';

const enhance = compose(
  connect(
    null,
    dispatch => ({
      openCityEditor: (entityId, cityData, elementRect) => {
        dispatch(CityActions.openCityEditor(entityId, cityData, elementRect));
      },
    })
  )
);

const stopBubblingUp = e => {
  e.stopPropagation();
  e.preventDefault();
};

class City extends Component {
  onEditClick() {
    const { block, contentState, openCityEditor } = this.props;
    const entityId = block.getEntityAt(0);
    const entity = contentState.getEntity(entityId);
    const cityData = entity.getData();
    const elementRect = this.rootElement.getBoundingClientRect();
    openCityEditor(entityId, cityData, elementRect);
  }

  getZoom(types) {
    if (!types) {
      return 10;
    }

    if (types.indexOf('country') >= 0) {
      return 8; // Zoomed out
    }
    if (types.indexOf('locality') >= 0) {
      return 12; // Zoomed in
    }

    return 10;
  }

  render() {
    const { block, contentState, onDragStart } = this.props;
    const entityId = block.getEntityAt(0);
    const entity = contentState.getEntity(entityId);
    const { name, description, location, types } = entity.getData();

    return (
      <div
        draggable="true"
        className="City City-in-editor-context"
        ref={div => {
          this.rootElement = div;
        }}
        role="presentation"
        onKeyDown={stopBubblingUp}
        onDragStart={onDragStart}
      >
        <div className="City-preview">
          <div
            className="City-scrim"
            onClick={this.onEditClick.bind(this)}
            role="presentation"
          >
            <h3>Click to Edit</h3>
          </div>
          <div className="City-map">
            <div className="City-map-inner">
              <CityMap
                center={location || { lat: 37.7749, lng: -122.4194 }}
                zoom={this.getZoom(types)}
              />
            </div>
          </div>
          <div className="City-details">
            <h3>{name || 'San Francisco, CA'}</h3>
            <p>
              {description || SF_DESCRIPTION}
              <span className="read-more">Read More ↓</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default enhance(City);
