import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import unionClassNames from 'union-class-names';
import * as PostActions from '../../actions/PostActions';
// import PostCityRelated from './PostCityRelated';
import CityMap from './CityMap';
import ExpandIcon from '../../icons/expand.svg';
import { SF_DESCRIPTION } from '../../constants/Strings';

const enhance = compose(
  connect(
    ({ post }, { cityData: { id } }) => ({
      isExpanded: post.cities[id] && post.cities[id].isExpanded,
    }),
    dispatch => ({
      setCityExpansion: (cityId, isExpanded) => {
        dispatch(PostActions.setCityExpansion(cityId, isExpanded));
      },
    })
  )
);

class PostCity extends Component {
  componentDidMount() {
    const { setCityExpansion, cityData } = this.props;
    setCityExpansion(cityData.id, false);
  }

  getZoom(types) {
    if (!types) {
      return 10;
    }

    if (types.indexOf('country') >= 0) {
      return 6; // Zoomed out
    }
    if (types.indexOf('locality') >= 0) {
      return 12; // Zoomed in
    }

    return 10;
  }

  render() {
    const { cityData, setCityExpansion, isExpanded } = this.props;
    const { name, description, id, location, types } = cityData;
    const classNames = unionClassNames(
      'City',
      isExpanded ? 'City-is-expanded' : ''
    );

    return (
      <div className={classNames}>
        <div className="City-preview">
          <div className="City-map">
            <div className="City-map-inner">
              <CityMap
                center={location || { lat: -34.397, lng: 150.644 }}
                zoom={this.getZoom(types)}
              />
            </div>
          </div>
          <div className="City-details">
            <button
              className="City-toggle"
              type="button"
              onClick={() => setCityExpansion(id, !isExpanded)}
            >
              <ExpandIcon />
            </button>
            <h3>{name}</h3>
            <p className="City-description">
              {description || SF_DESCRIPTION}
              <button
                className="read-more button"
                type="button"
                onClick={() => setCityExpansion(id, !isExpanded)}
              >
                Read More ↓
              </button>
            </p>
          </div>
        </div>
        {/* <div className="City-related">
          <div className="City-related-item">
            <PostCityRelated title="Destinations" to="/posts/" />
          </div>
          <div className="City-related-item">
            <PostCityRelated title="Posts" to="/posts/" />
          </div>
        </div> */}
      </div>
    );
  }
}

export default enhance(PostCity);
