import { CompositeDecorator, EditorState } from 'draft-js';
import MultiDecorator from 'draft-js-plugins-editor/lib/Editor/MultiDecorator';
import { onwardDecorators } from './containers/editorConfig';

export const editor = {
  editorState: EditorState.createEmpty(
    new MultiDecorator([new CompositeDecorator(onwardDecorators)])
  ),
  showRawState: false,
  coverImage: {
    dataUrl: null,
    url: null,
    servingUrl: null,
    isUploading: false,
  },
  title: '',
  isFirestoreDataLoaded: false,
  placeTagSuggestions: [],
  hasUploadingImages: false,
  author: null,
};

export const cityEditor = {
  isOpen: false,
  entityKey: null,
  elementRect: null,
  cityData: {
    name: null,
    id: null,
  },
};

export const post = {
  cities: {},
};

// export const post = {
//   cities: {
//     mycityid1: {
//       isExpanded: false,
//     }
//   }
// }

export const toasts = {};

// export const toasts = {
//   toastId1: {
//     isActive: false,
//     message: null,
//     buttonLabel: null,
//     buttonTarget: null,
//   }
// }

const initialState = {
  editor,
  cityEditor,
  post,
  toasts,
};

export default initialState;
