// EditorActions
export const EDITOR_UPDATE_EDITOR_STATE = 'EDITOR_UPDATE_EDITOR_STATE';
export const EDITOR_UPDATE_TITLE = 'EDITOR_UPDATE_TITLE';
export const EDITOR_CLEAR_EDITOR_STATE = 'EDITOR_CLEAR_EDITOR_STATE';
export const EDITOR_TOGGLE_RAW_STATE = 'EDITOR_TOGGLE_RAW_STATE';
export const EDITOR_INSERT_CITY = 'EDITOR_INSERT_CITY';
export const EDITOR_UPDATE_CITY = 'EDITOR_UPDATE_CITY';
export const EDITOR_LOAD_FIRESTORE_DATA = 'EDITOR_LOAD_FIRESTORE_DATA';
export const EDITOR_UPDATE_COVER_IMAGE = 'EDITOR_UPDATE_COVER_IMAGE';
export const EDITOR_DROP_COVER_IMAGE = 'EDITOR_DROP_COVER_IMAGE';
export const EDITOR_DISPLAY_COVER_IMAGE = 'EDITOR_DISPLAY_COVER_IMAGE';
export const EDITOR_CLEAN_UP_COVER_IMAGE_DATA_URL =
  'EDITOR_CLEAN_UP_COVER_IMAGE_DATA_URL';
export const EDITOR_UPDATE_PLACE_TAG_SUGGESTIONS =
  'EDITOR_UPDATE_PLACE_TAG_SUGGESTIONS';
export const EDITOR_ADD_UPLOADING_IMAGE = 'EDITOR_ADD_UPLOADING_IMAGE';
export const EDITOR_REMOVE_UPLOADING_IMAGE = 'EDITOR_REMOVE_UPLOADING_IMAGE';
export const EDITOR_UPDATE_AUTHOR = 'EDITOR_UPDATE_AUTHOR';
export const EDITOR_UPDATE_HAS_UPLOADING_IMAGES =
  'EDITOR_UPDATE_HAS_UPLOADING_IMAGES';

// CityActions
export const CITY_OPEN_EDITOR = 'CITY_OPEN_EDITOR';
export const CITY_CLOSE_EDITOR = 'CITY_CLOSE_EDITOR';
export const CITY_UPDATE_DATA = 'CITY_UPDATE_DATA';

// PostActions
export const POST_SET_CITY_EXPANSION = 'POST_SET_CITY_EXPANSION';

// ToastActions
export const CREATE_TOAST = 'CREATE_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const DELETE_TOAST = 'DELETE_TOAST';
