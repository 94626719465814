import { EditorState, SelectionState } from 'draft-js';
import addBlock from '../plugins/draft-js-drag-n-drop-plugin/modifiers/addBlock';
import removeBlock from '../plugins/draft-js-drag-n-drop-plugin/modifiers/removeBlock';
// import moveBlock from '../plugins/draft-js-drag-n-drop-plugin/modifiers/moveBlock';

export default function moveBlockOrCreateGrid(
  editorState,
  selection,
  blockKey
) {
  const currentContent = editorState.getCurrentContent();
  const block = currentContent.getBlockForKey(blockKey);
  const entity = currentContent.getEntity(block.getEntityAt(0));

  const targetSelection = currentContent.getSelectionAfter();
  const blockKeyForTarget = targetSelection.get('focusKey');
  const blockForTarget = currentContent.getBlockForKey(blockKeyForTarget);
  const entityKeyForTarget = blockForTarget.getEntityAt(0);
  const entityForTarget = entityKeyForTarget
    ? currentContent.getEntity(entityKeyForTarget)
    : null;

  console.log(entity.type, entityForTarget && entityForTarget.type);

  const contentWithNewBlock = addBlock(
    editorState,
    selection,
    block.getType(),
    entity.data,
    entity.type
  );
  const contentWithoutOldBlock = removeBlock(contentWithNewBlock, blockKey);
  // force to new selection
  const newSelection = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockKey,
    focusOffset: 0,
  });
  return EditorState.forceSelection(
    EditorState.push(editorState, contentWithoutOldBlock, 'insert-fragment'),
    newSelection
  );
}
