import React, { Component } from 'react';
import unionClassNames from 'union-class-names';
import { Link } from 'react-router-dom';

class Toast extends Component {
  render() {
    const {
      id,
      isActive,
      message,
      buttonLabel,
      buttonTarget,
      dismiss,
    } = this.props;
    const className = unionClassNames('Toast', isActive ? ' Toast-active' : '');
    return (
      <div className={className}>
        <span className="Toast-message">{message}</span>
        {buttonLabel && buttonTarget ? (
          <Link
            className="Toast-button text-button green"
            to={buttonTarget}
            onClick={() => dismiss(id)}
          >
            {buttonLabel}
          </Link>
        ) : (
          <span />
        )}
      </div>
    );
  }
}

export default Toast;
