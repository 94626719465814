import React, { Component } from 'react';
import unionClassNames from 'union-class-names';
import Mousetrap from 'mousetrap';
import LinkIcon from '../icons/link.svg';
import LinkPasteBox from './LinkPasteBox';

const preventBubblingUp = event => {
  event.preventDefault();
  event.stopPropagation();
};

class LinkButton extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind('mod+k', this.onClick);
    document.addEventListener('onward-link', this.onClick);
  }

  componentWillUnmount() {
    Mousetrap.unbind('mod+k');
    document.removeEventListener('onward-link', this.onClick);
  }

  onClick(event) {
    const { onOverrideContent, getEditorState } = this.props;

    const editorState = getEditorState();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);

    if (selectedText.length > 0) {
      // A button can call `onOverrideContent` to replace the content
      // of the toolbar. This can be useful for displaying sub
      // menus or requesting additional information from the user.
      event.preventDefault();
      onOverrideContent(LinkPasteBox);
    }
  }

  render() {
    const { theme } = this.props;
    return (
      <div
        className={unionClassNames('LinkButton', theme.buttonWrapper)}
        role="presentation"
        onMouseDown={preventBubblingUp}
      >
        <button type="button" onClick={this.onClick} className={theme.button}>
          <LinkIcon />
        </button>
      </div>
    );
  }
}

export default LinkButton;
