import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

const preventBubblingUp = event => {
  event.stopPropagation();
};

class CityEditorInput extends Component {
  componentDidMount() {
    const { cityName } = this.props;
    this.textInput.focus();
    this.textInput.setSelectionRange(0, cityName.length);
  }

  handleFocus(event) {
    event.target.select();
  }

  render() {
    const { cityName, onChange, onSelectPlace } = this.props;

    return (
      <div
        className="CityEditorInput"
        role="presentation"
        onClick={preventBubblingUp}
        onMouseDown={preventBubblingUp}
      >
        <PlacesAutocomplete
          onSelect={onSelectPlace}
          onChange={onChange}
          styles={{
            autocompleteContainer: {
              zIndex: 1000,
            },
          }}
          value={cityName}
          onFocus={this.handleFocus}
          searchOptions={{ types: ['(regions)'] }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <React.Fragment>
              <input
                {...getInputProps({
                  placeholder: 'San Francisco, CA',
                  className: 'location-search-input',
                  ref: input => {
                    this.textInput = input;
                  },
                })}
              />
              {suggestions.length > 0 && (
                <div className="autocomplete-dropdown-container">
                  {loading && (
                    <div className="autocomplete-dropdown-container-loading">
                      Loading...
                    </div>
                  )}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </React.Fragment>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

export default CityEditorInput;
