import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="9" stroke="#ccc" strokeWidth="2" />
      <path
        stroke="#ccc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8v8M8 12h8"
      />
    </g>
  </svg>
);
