import React, { Component } from 'react';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

class Link extends Component {
  render() {
    const { contentState, entityKey, children } = this.props;
    const { url } = contentState.getEntity(entityKey).getData();
    return (
      <a href={url} className="link">
        {children}
      </a>
    );
  }
}

export default {
  strategy: findLinkEntities,
  component: Link,
};
