import React, { Component } from 'react';
import PostCard from '../components/PostCard';
import PostList from '../components/PostList';
import HomePageBanner from '../components/HomePageBanner';

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="Home-content">
          <HomePageBanner />
          <PostList className="Home-posts" component={PostCard} />
        </div>
      </div>
    );
  }
}
