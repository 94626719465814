import React, { Component } from 'react';
import { EditorState, RichUtils } from 'draft-js';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import unionClassNames from 'union-class-names';
import PinIcon from '../icons/pin.svg';

const preventBubblingUp = event => {
  event.preventDefault();
  event.stopPropagation();
};

class PlaceSearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
    this.onChange = address => this.setState({ address });
    this.onSelectPlace = this.onSelectPlace.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
    this.textInput.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onSelectPlace() {
    const { getEditorState, setEditorState } = this.props;
    const { address } = this.state;
    // Do something with this place.
    geocodeByAddress(address)
      .then(results => {
        const editorState = getEditorState();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
          const contentState = editorState.getCurrentContent();
          const placeId = results[0].place_id;

          const contentStateWithEntity = contentState.createEntity(
            'PLACELINK',
            'MUTABLE',
            { placeId }
          );

          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
          });
          setEditorState(
            RichUtils.toggleLink(
              newEditorState,
              newEditorState.getSelection(),
              entityKey
            )
          );
        }
      })
      .catch(error => console.error('Error', error));

    // Close popup
    this.onWindowClick();
  }

  onWindowClick() {
    const { onOverrideContent } = this.props;
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    onOverrideContent(undefined);
  }

  render() {
    const { theme } = this.props;
    const { address } = this.state;
    const classNames = unionClassNames(
      theme.buttonWrapper,
      'PlaceForm-wrapper'
    );

    return (
      <div
        className={classNames}
        onMouseDown={preventBubblingUp}
        role="presentation"
      >
        <form className="PlaceForm">
          <PinIcon />

          <PlacesAutocomplete
            value={address}
            onSelect={this.onSelectPlace}
            onChange={this.onChange}
            onClick={preventBubblingUp}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <React.Fragment>
                <input
                  {...getInputProps({
                    placeholder: 'San Francisco, CA',
                    className: 'location-search-input',
                    ref: input => {
                      this.textInput = input;
                    },
                  })}
                />
                {suggestions.length > 0 && (
                  <div className="autocomplete-dropdown-container">
                    {loading && (
                      <div className="autocomplete-dropdown-container-loading">
                        Loading...
                      </div>
                    )}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            )}
          </PlacesAutocomplete>
        </form>
      </div>
    );
  }
}

export default PlaceSearchBox;
