import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';

import BodyElementProxy from '../components/BodyElementProxy';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Toasts from '../components/Toasts';
import Editor from './Editor';
import Home from './Home';
import Post from './Post';
import User from './User';
import NotFound from './NotFound';

const enhance = compose(
  withRouter,
  firebaseConnect(),
  connect(({ firebase: { auth } }) => ({ auth }))
);

class App extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { auth } = this.props;
    return (
      <div className="App">
        <Navbar />
        <Toasts />
        {!isLoaded(auth) ? (
          <span>Loading...</span>
        ) : (
          <Switch>
            <Route exact key="/" path="/" component={Home} />
            <Route
              exact
              key="/posts/:postId/"
              path="/posts/:postId/"
              component={Post}
            />
            <Route exact key="/new" path="/new" component={Editor} />
            <Route
              exact
              key="/posts/:postId/edit"
              path="/posts/:postId/edit"
              component={Editor}
            />
            <Route
              exact
              key="/users/@:handle/"
              path="/users/@:handle/"
              component={User}
            />
            <Route
              exact
              key="/users/:userId/"
              path="/users/:userId/"
              component={User}
            />
            <Route component={NotFound} />
          </Switch>
        )}
        <BodyElementProxy />
        <Footer />
      </div>
    );
  }
}

export default enhance(App);
