import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(6 5)"
      >
        <path d="M6 15c4-2.67 6-5.67 6-9A6 6 0 1 0 0 6c0 3.325 2 6.325 6 9z" />
        <circle cx="6" cy="6" r="2" />
      </g>
    </g>
  </svg>
);
