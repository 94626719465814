import { getDefaultKeyBinding, RichUtils } from 'draft-js';

const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
// const isWin = navigator.platform.toUpperCase().indexOf('MAC')>=0;

function hasAltGraph(e) {
  if (isMac) {
    return e.metaKey && e.altKey;
  }

  // Implied: Windows or Linux
  return e.ctrlKey && e.altKey;
}

function hasCommandShift(e) {
  if (isMac) {
    return e.metaKey && e.shiftKey;
  }

  // Implied: Windows or Linux
  return e.ctrlKey && e.shiftKey;
}

function hasCommand(e) {
  if (isMac) {
    return e.metaKey;
  }

  // Implied: Windows or Linux
  return e.ctrlKey;
}

const keyBindings = [
  {
    keyCode: 49 /* `1` key */,
    modifier: hasAltGraph,
    command: 'header-two',
    blockType: 'header-two',
  },
  {
    keyCode: 50 /* `2` key */,
    modifier: hasAltGraph,
    command: 'header-three',
    blockType: 'header-three',
  },
  {
    keyCode: 53 /* `5` key */,
    modifier: hasAltGraph,
    command: 'blockquote',
    blockType: 'blockquote',
  },
  {
    keyCode: 54 /* `6` key */,
    modifier: hasAltGraph,
    command: 'code-block',
    blockType: 'code-block',
  },
  {
    keyCode: 55 /* `7` key */,
    modifier: hasCommandShift,
    command: 'ordered-list-item',
    blockType: 'ordered-list-item',
  },
  {
    keyCode: 56 /* `8` key */,
    modifier: hasCommandShift,
    command: 'unordered-list-item',
    blockType: 'unordered-list-item',
  },
  {
    keyCode: 83 /* `s` key */,
    modifier: hasCommand,
    command: 'onward-save',
    dispatchGlobal: true,
  },
  {
    keyCode: 75 /* `k` key */,
    modifier: hasCommand,
    command: 'onward-link',
    dispatchGlobal: true,
  },
];

const keyboardShortcutsPlugin = {
  keyBindingFn(e) {
    // Register bindings
    for (let i = 0; i < keyBindings.length; i++) {
      if (e.keyCode === keyBindings[i].keyCode && keyBindings[i].modifier(e)) {
        return keyBindings[i].command;
      }
    }

    // Default
    return getDefaultKeyBinding(e);
  },

  handleKeyCommand(command, editorState, _ref) {
    // Custom shortcuts
    for (let i = 0; i < keyBindings.length; i++) {
      const binding = keyBindings[i];
      if (binding.command === command) {
        if (binding.dispatchGlobal) {
          document.dispatchEvent(new CustomEvent(binding.command));
          return 'handled';
        }
        if (binding.blockType !== undefined) {
          _ref.setEditorState(
            RichUtils.toggleBlockType(editorState, binding.blockType)
          );
        }
        return 'handled';
      }
    }

    // Default DraftJS shortcuts
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      _ref.setEditorState(newState);
      return 'handled';
    }

    // Not handled
    return 'not-handled';
  },
};

export default keyboardShortcutsPlugin;
