import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as EditorActions from '../../actions/EditorActions';

const enhance = compose(
  connect(
    () => ({}),
    dispatch => ({
      onInsertCityClick: () => {
        dispatch(EditorActions.insertCity());
      },
    })
  )
);

class InsertCityButton extends Component {
  render() {
    const { onInsertCityClick } = this.props;
    return (
      <li>
        <button type="button" className="button" onClick={onInsertCityClick}>
          Insert
        </button>
      </li>
    );
  }
}

export default enhance(InsertCityButton);
