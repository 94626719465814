import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M15.787 12.41c-.552.41-1.23.616-1.914.588A4.002 4.002 0 0 0 6 14a4 4 0 0 0 6.764 2.892c.834.15 1.68.144 2.498-.007a6 6 0 1 1 .525-4.475z" />
        <path d="M8.213 11.59a3.012 3.012 0 0 1 1.914-.588A4.002 4.002 0 0 0 18 10a4 4 0 0 0-6.764-2.892 6.993 6.993 0 0 0-2.498.007 6 6 0 1 1-.525 4.475z" />
      </g>
    </g>
  </svg>
);
