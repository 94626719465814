import * as types from '../constants/ActionTypes';
import { post as initialState } from '../initialState';

export default function post(state = initialState, action) {
  switch (action.type) {
    case types.POST_SET_CITY_EXPANSION:
      return Object.assign({}, state, {
        cities: Object.assign({}, state.cities, {
          [action.cityId]: Object.assign({}, state.cities[action.cityId], {
            isExpanded: action.isExpanded,
          }),
        }),
      });
    default:
      return state;
  }
}
