import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

const enhance = compose(
  connect(state => ({
    isCityEditorOpen: state.cityEditor.isOpen,
  }))
);

class BodyElementProxy extends Component {
  componentDidMount() {
    const { isCityEditorOpen } = this.props;
    document.body.classList.toggle('is-CityEditor-open', isCityEditorOpen);
  }

  componentWillReceiveProps(nextProps) {
    document.body.classList.toggle(
      'is-CityEditor-open',
      nextProps.isCityEditorOpen
    );
  }

  componentWillUnmount() {
    document.body.classList.remove('is-CityEditor-open');
  }

  render() {
    const { children } = this.props;
    return children || null;
  }
}
export default enhance(BodyElementProxy);
